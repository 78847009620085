@font-face {
  font-family: "Geomanist";
  src: url("./assets/fonts/geomanist-regular-webfont.eot");
  src: local("☺"),
    url("./assets/fonts/geomanist-regular-webfont.woff") format("woff"),
    url("./assets/fonts/geomanist-regular-webfont.ttf") format("truetype"),
    url("./assets/fonts/geomanist-regular-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Geomanist";
  src: url("./assets/fonts/geomanist-book-webfont.eot");
  src: local("☺"),
    url("./assets/fonts/geomanist-book-webfont.woff") format("woff"),
    url("./assets/fonts/geomanist-book-webfont.ttf") format("truetype"),
    url("./assets/fonts/geomanist-book-webfont.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Geomanist";
  src: url("./assets/fonts/geomanist-medium-webfont.eot");
  src: local("☺"),
    url("./assets/fonts/geomanist-medium-webfont.woff") format("woff"),
    url("./assets/fonts/geomanist-medium-webfont.ttf") format("truetype"),
    url("./assets/fonts/geomanist-medium-webfont.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7fb;
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

fieldset {
  border: none;
}

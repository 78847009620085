.categories {
  margin-top: 22px;
  margin-bottom: 24px;
}
.categories .heading {
  margin: 0 0 16px;
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 500;
}
.categories .list {
  margin: 0 -24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
.categories .list.slick-initialized {
  display: block;
}
.categories .list.slick-initialized .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.categories .list.slick-initialized .slick-list {
  overflow: visible;
}
.categories .list.slick-initialized .slick-slide {
  float: none;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
  height: inherit;
}
.categories .list.slick-initialized .slick-slide.slick-current,
.categories .list.slick-initialized .slick-slide.slick-active {
  opacity: 1;
}
.categories .list.slick-initialized .slick-slide div {
  height: 100%;
}
.categories .list.slick-initialized .slick-dots {
  padding: 0;
  margin: 20px 0 0 0;
  list-style: none;
  text-align: center;
  z-index: 10;
}
.categories .list.slick-initialized .slick-dots li {
  margin: 0 6px;
  display: inline-block;
  vertical-align: middle;
}
.categories .list.slick-initialized .slick-dots li .dot {
  width: 9px;
  height: 9px;
  display: block;
  background: rgba(14, 99, 244, 0.2);
  border-radius: 50%;
  cursor: pointer;
}
.categories .list.slick-initialized .slick-dots li.slick-active .dot {
  background: rgba(14, 99, 244, 0.7);
}
.categories .list .item {
  min-width: 100%;
  height: 100%;
  display: block !important;
  padding: 0 24px;
}
.categories .list .card {
  height: 100%;
  display: block;
  background: #ecf2fe;
  border-radius: 3px;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  position: relative;
  color: #5d6d82;
}
.categories .list .card .image {
  width: 100px;
  margin: 0;
  display: none;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.categories .list .card .image img {
  width: 100%;
  height: auto;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}
.categories .list .card .text {
  height: 100%;
  padding: 28px 25px 25px;
  font-size: 16px;
  line-height: 24px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.categories .list .card .text p:last-of-type {
  margin-bottom: 0;
}
.categories .list .card .title {
  margin: 0 0 8px 0;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #0e1952;
  font-weight: 500;
}

@media (min-width: 750px) {
  .categories {
    margin-top: 40px;
    margin-bottom: 48px;
  }
  .categories .heading {
    margin-bottom: 20px;
  }
  .categories .list {
    margin: 0 -12px;
  }
  .categories .list .item {
    min-width: 0;
    padding-right: 12px;
    padding-left: 12px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .categories .list .card .text {
    padding: 26px 30px;
  }
}

@media (min-width: 1024px) {
  .categories {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .categories .heading {
    margin-bottom: 16px;
    font-size: 30px;
    line-height: 1.24em;
  }
  .categories .list .item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3%;
    flex: 0 0 33.3%;
  }
}

@media (min-width: 1440px) {
  .categories {
    margin-bottom: 76px;
  }
  .categories .heading {
    font-size: 30px;
    line-height: 1.23em;
  }
  .categories .list {
    margin: 0 -16px;
  }
  .categories .list .item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3%;
    flex: 0 0 33.3%;
    padding-right: 16px;
    padding-left: 16px;
  }
  .categories .list .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .categories .list .card .image {
    display: block;
  }
}

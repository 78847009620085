.marketplace-body {
  background: #fff;
}

.catalog .content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.catalog .content > *:first-of-type {
  margin-top: 0;
}

.publishers {
  margin: 44px 0;
}
.publishers + .section {
  margin-top: 64px;
}
.publishers .heading {
  margin: 0;
  font-size: 25px;
  line-height: 1.2em;
  font-weight: 500;
  color: #0e1952;
}
.publishers .title {
  margin: 0 0 12px;
  font-size: 24px;
  line-height: 1.2em;
}
.publishers .subtitle {
  font-size: 16px;
  line-height: 28px;
}
.publishers .list {
  margin: -10px -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.publishers .list.carousel {
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
}
.publishers .list.carousel .item {
  padding-top: 30px;
  padding-bottom: 35px;
  width: 100%;
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.publishers .list .item {
  padding: 10px 10px;
}
.publishers .list .item p,
.publishers .list .item strong {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.publishers .list .item strong {
  padding-right: 25px;
}
.publishers .list .item .description {
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.publishers .list .item .description.line-clamp-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.publishers .list .item .description.line-clamp-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.card .description {
  max-width: 244px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.card .company {
  max-width: 244px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.publishers .list .card {
  background: white;
  -webkit-box-shadow: 0 18px 40px 0 rgba(52, 77, 131, 0.08);
  box-shadow: 0 18px 40px 0 rgba(52, 77, 131, 0.08);
  font-size: 14px;
  line-height: 24px;
  position: relative;
}
.publishers .list .card .link {
  width: 100%;
  height: 100%;
  padding: 25px 18px 35px 78px;
  display: block;
  color: #5d6d82;
  text-decoration: none;
}
.publishers .list .card p:last-of-type {
  margin-bottom: 0;
}
.publishers .list .card .logo {
  width: 40px;
  height: 40px;
  margin: 0;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  left: 20px;
  top: 22px;
}
.publishers .list .card .logo img {
  width: 100%;
  height: auto;
  display: block;
}
.publishers .list .card .verified:before {
  content: "";
  display: block;
  overflow: hidden;
  width: 20px;
  height: 20px;
  background: white
    url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxM3B4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxMyAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iTGFuZGluZ3MtcGFnZXMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkhvbWVwYWdlX18zMjAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNi4wMDAwMDAsIC0zOTU0LjAwMDAwMCkiIGZpbGw9IiMxMjU2RUMiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI0LjAwMDAwMCwgMzUyOC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIuMDAwMDAwLCA0MTYuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMC4xNSwxNS41IEMwLjA1LDE1LjQgMCwxNS4yNSAwLDE1LjE1IEMwLDE1LjA1IDAuMDUsMTQuOSAwLjE1LDE0LjggTDAuODUsMTQuMSBDMS4wNSwxMy45IDEuMzUsMTMuOSAxLjU1LDE0LjEgTDEuNiwxNC4xNSBMNC4zNSwxNy4xIEM0LjQ1LDE3LjIgNC42LDE3LjIgNC43LDE3LjEgTDExLjQsMTAuMTUgTDExLjQ1LDEwLjE1IEMxMS42NSw5Ljk1IDExLjk1LDkuOTUgMTIuMTUsMTAuMTUgTDEyLjg1LDEwLjg1IEMxMy4wNSwxMS4wNSAxMy4wNSwxMS4zNSAxMi44NSwxMS41NSBMNC44NSwxOS44NSBDNC43NSwxOS45NSA0LjY1LDIwIDQuNSwyMCBDNC4zNSwyMCA0LjI1LDE5Ljk1IDQuMTUsMTkuODUgTDAuMjUsMTUuNjUgTDAuMTUsMTUuNSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==")
    center center no-repeat;
  background-size: 10px auto;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  right: -4px;
}
.publishers .list .card .top_rated_icon:before {
  content: "";
  display: block;
  overflow: hidden;
  width: 20px;
  height: 20px;
  background: white
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgNTc2IDUxMiI+PCEtLSEgRm9udCBBd2Vzb21lIFBybyA2LjQuMiBieSBAZm9udGF3ZXNvbWUgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbSBMaWNlbnNlIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20vbGljZW5zZSAoQ29tbWVyY2lhbCBMaWNlbnNlKSBDb3B5cmlnaHQgMjAyMyBGb250aWNvbnMsIEluYy4gLS0+PHN0eWxlPnN2Z3tmaWxsOiMyODU1ZTN9PC9zdHlsZT48cGF0aCBkPSJNMzA5IDEwNmMxMS40LTcgMTktMTkuNyAxOS0zNGMwLTIyLjEtMTcuOS00MC00MC00MHMtNDAgMTcuOS00MCA0MGMwIDE0LjQgNy42IDI3IDE5IDM0bC01LjggMTEuNkwyMDkuNyAyMjAuNmMtOS4xIDE4LjItMzIuNyAyMy40LTQ4LjYgMTAuN2wtNzItNTcuNkw3MiAxNjBjNS02LjcgOC0xNSA4LTI0YzAtMjIuMS0xNy45LTQwLTQwLTQwUzAgMTEzLjkgMCAxMzZzMTcuOSA0MCA0MCA0MGMuMiAwIC41IDAgLjcgMGw0LjQgMjMuOUw4Ni40IDQyNy40YzUuNSAzMC40IDMyIDUyLjYgNjMgNTIuNkg0MjYuNmMzMC45IDAgNTcuNC0yMi4xIDYzLTUyLjZsNDEuNC0yMjcuNSA0LjQtMjMuOWMuMiAwIC41IDAgLjcgMGMyMi4xIDAgNDAtMTcuOSA0MC00MHMtMTcuOS00MC00MC00MHMtNDAgMTcuOS00MCA0MGMwIDkgMyAxNy4zIDggMjRsLTE3LjEgMTMuNy03MiA1Ny42Yy0xNS45IDEyLjctMzkuNSA3LjUtNDguNi0xMC43TDMxNC44IDExNy43IDMwOSAxMDZ6TTEzMy43IDQxOC45TDEwMi4yIDI0NS42bDI4LjkgMjMuMWMzOS44IDMxLjggOTguOCAxOC45IDEyMS41LTI2LjdMMjg4IDE3MS4zbDM1LjQgNzAuN2MyMi44IDQ1LjYgODEuOCA1OC41IDEyMS41IDI2LjdsMjguOS0yMy4xTDQ0Mi4zIDQxOC45Yy0xLjQgNy42LTggMTMuMS0xNS43IDEzLjFIMTQ5LjRjLTcuNyAwLTE0LjQtNS41LTE1LjctMTMuMXoiLz48L3N2Zz4=")
    center center no-repeat;
  background-size: 12px auto;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  right: -4px;
}
.publishers .list .card .company {
  margin: 0 0 2px 0;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #0e1952;
  font-weight: 500;
}
.publishers .list .card .specialization {
  margin-bottom: 8px;
  color: #0e63f4;
  font-size: 16px;
  line-height: 20px;
}
.publishers .list .card .like {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 18px;
  right: 18px;
}
.publishers .list .card .like::before,
.publishers .list .card .like::after {
  content: "";
  display: block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.publishers .list .card .like:before {
  opacity: 0.4;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTEuNiA0LjljMCAyLjYgMi40IDUuNyA2LjQgOC4zIDQtMi43IDYuNC01LjcgNi40LTguMyAwLTEuOC0xLjQtMy4zLTMuMi0zLjMtLjggMC0xLjYuMy0yLjEuOGwtLjguOGMtLjIuMi0uNC4yLS42IDBsLS44LS43Yy0uNS0uNi0xLjMtLjktMi4xLS45LTEuOCAwLTMuMiAxLjUtMy4yIDMuM3pNOCAxLjNDOC45LjQgMTAgMCAxMS4yIDAgMTMuOSAwIDE2IDIuMiAxNiA0LjljMCA0LTQgNy44LTcuNyAxMC4xLS4yLjEtLjUuMS0uNyAwQzQgMTIuOCAwIDguOSAwIDQuOSAwIDIuMiAyLjEgMCA0LjggMCA2IDAgNy4yLjUgOCAxLjN6IiBmaWxsPSIjNjI3MjgzIi8+PC9zdmc+");
}
.publishers .list .card .like:after {
  opacity: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuNiA0Ljk0MmMwIDIuNTczIDIuNDQ5IDUuNjg0IDYuNDA1IDguMzQ3QzExLjk2IDEwLjYyNiAxNC40IDcuNTgyIDE0LjQgNC45NDJjMC0xLjgyLTEuNDMzLTMuMjk1LTMuMi0zLjI5NS0uOCAwLTEuNTUuMzAxLTIuMTMzLjgzOWwtLjc3LjcxYS40NDQuNDQ0IDAgMCAxLS41OTQgMGwtLjc3LS43MUEzLjEzIDMuMTMgMCAwIDAgNC44IDEuNjQ3Yy0xLjc2NyAwLTMuMiAxLjQ3NS0zLjIgMy4yOTV6TTggMS4yNThBNC43MDUgNC43MDUgMCAwIDEgMTEuMiAwQzEzLjg1MSAwIDE2IDIuMjEzIDE2IDQuOTQyYzAgMy45ODQtNC4wMDUgNy44MjUtNy42NiAxMC4wNzlhLjY4Ni42ODYgMCAwIDEtLjY3Ny4wMDJDMy45ODMgMTIuNzc2IDAgOC45MjYgMCA0Ljk0MyAwIDIuMjEyIDIuMTQ5IDAgNC44IDAgNi4wMyAwIDcuMTUuNDc2IDggMS4yNTh6IiBmaWxsPSIjMTI1NmVjIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
}
.publishers .list .card .like:hover::before {
  opacity: 1;
}
.publishers .list .card .like:focus::before {
  opacity: 0.4;
}
.publishers .list .card.liked .like::before {
  opacity: 0;
}
.publishers .list .card.liked .like::after {
  opacity: 1;
}
.step-final .publishers .list .card {
  -webkit-box-shadow: 0 18px 25px 0 rgba(52, 77, 131, 0.08);
  box-shadow: 0 18px 25px 0 rgba(52, 77, 131, 0.08);
}

.ais-Hits {
  width: 100%;
}

.ais-Hits-list {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ais-Hits-item {
  max-width: 50%;
  width: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ais-Hits-item .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ais-Hits-item .item {
  -ms-flex-preferred-size: 100% !important;
  flex-basis: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
}

@media (max-width: 500px) {
  .ais-Hits-item {
    max-width: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 750px) {
  .publishers {
    margin: 64px 0;
  }
  .publishers .title {
    font-size: 25px;
  }
  .publishers .subtitle {
    margin-bottom: 36px;
  }
  .publishers .list {
    margin: -14px -12px;
  }
  .publishers .list .item {
    padding: 14px 12px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .publishers .list .card {
    font-size: 16px;
    line-height: 26px;
  }
  .publishers .list .card .link {
    padding: 25px 20px 35px 84px;
  }
  .publishers .list .card .logo {
    position: absolute;
    left: 24px;
    top: 20px;
  }
  .publishers .list .card .specialization {
    margin-bottom: 6px;
  }
}

@media (min-width: 1024px) {
  .catalog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

#searchbox {
  flex-grow: 0;
  margin-bottom: 8px;
}

#searchbox,
#stats {
  flex-grow: 0;
}

.ais-SearchBox {
  position: relative;
  height: auto;
  width: 100%;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  position: absolute;
  top: 0;
  left: inherit;
  margin: 14px 16px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.ais-SearchBox-submit svg,
.ais-SearchBox-reset svg {
  width: 13px;
  height: 13px;
  display: block;
}

.ais-SearchBox-submit svg {
  fill: #5468ff;
}

.ais-SearchBox-reset svg {
  fill: #ed5a6a;
}

.ais-SearchBox-submit {
  right: 0;
}

.ais-SearchBox-reset {
  background: none;
  padding: 0;
  border: none;
  right: 30px;
}

.ais-SearchBox-loadingIndicator {
  position: absolute;
  top: 11px;
  right: 29px;
}

.ais-RefinementList-list {
  list-style: none;
  padding: 0;
}

.form-checkbox,
.form-radio {
  padding-left: 24px;
  position: relative;
  overflow: hidden;
  display: block;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-checkbox input[type="checkbox"],
.form-checkbox input[type="radio"],
.form-radio input[type="checkbox"],
.form-radio input[type="radio"] {
  position: absolute;
  top: -100px;
  left: -100px;
}
.form-checkbox input[type="checkbox"]:checked ~ .dot:before,
.form-checkbox input[type="radio"]:checked ~ .dot:before,
.form-radio input[type="checkbox"]:checked ~ .dot:before,
.form-radio input[type="radio"]:checked ~ .dot:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.form-checkbox .dot,
.form-radio .dot {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(93, 109, 130, 0.5);
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 2px;
  background: white;
}
.form-checkbox .dot:before,
.form-radio .dot:before {
  content: "";
  display: block;
  overflow: hidden;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  opacity: 0;
  background: #0e63f4;
  border-radius: 2px;
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.form-radio .dot,
.form-radio .dot:before {
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-checkbox .dot:before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxM3B4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxMyAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iTGFuZGluZ3MtcGFnZXMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkhvbWVwYWdlX18zMjAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNi4wMDAwMDAsIC0zOTU0LjAwMDAwMCkiIGZpbGw9IiMxMjU2RUMiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI0LjAwMDAwMCwgMzUyOC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIuMDAwMDAwLCA0MTYuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMC4xNSwxNS41IEMwLjA1LDE1LjQgMCwxNS4yNSAwLDE1LjE1IEMwLDE1LjA1IDAuMDUsMTQuOSAwLjE1LDE0LjggTDAuODUsMTQuMSBDMS4wNSwxMy45IDEuMzUsMTMuOSAxLjU1LDE0LjEgTDEuNiwxNC4xNSBMNC4zNSwxNy4xIEM0LjQ1LDE3LjIgNC42LDE3LjIgNC43LDE3LjEgTDExLjQsMTAuMTUgTDExLjQ1LDEwLjE1IEMxMS42NSw5Ljk1IDExLjk1LDkuOTUgMTIuMTUsMTAuMTUgTDEyLjg1LDEwLjg1IEMxMy4wNSwxMS4wNSAxMy4wNSwxMS4zNSAxMi44NSwxMS41NSBMNC44NSwxOS44NSBDNC43NSwxOS45NSA0LjY1LDIwIDQuNSwyMCBDNC4zNSwyMCA0LjI1LDE5Ljk1IDQuMTUsMTkuODUgTDAuMjUsMTUuNjUgTDAuMTUsMTUuNSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==");
  background-color: transparent;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.form-checkbox,
.form-radio {
  padding-left: 24px;
  position: relative;
  overflow: hidden;
  display: block;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-checkbox input[type="checkbox"],
.form-checkbox input[type="radio"],
.form-radio input[type="checkbox"],
.form-radio input[type="radio"] {
  position: absolute;
  top: -100px;
  left: -100px;
}
.form-checkbox input[type="checkbox"]:checked ~ .dot:before,
.form-checkbox input[type="radio"]:checked ~ .dot:before,
.form-radio input[type="checkbox"]:checked ~ .dot:before,
.form-radio input[type="radio"]:checked ~ .dot:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.form-checkbox .dot,
.form-radio .dot {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(93, 109, 130, 0.5);
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 2px;
  background: white;
}
.form-checkbox .dot:before,
.form-radio .dot:before {
  content: "";
  display: block;
  overflow: hidden;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  opacity: 0;
  background: #0e63f4;
  border-radius: 2px;
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.form-radio .dot,
.form-radio .dot:before {
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-checkbox .dot:before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxM3B4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxMyAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iTGFuZGluZ3MtcGFnZXMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkhvbWVwYWdlX18zMjAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNi4wMDAwMDAsIC0zOTU0LjAwMDAwMCkiIGZpbGw9IiMxMjU2RUMiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI0LjAwMDAwMCwgMzUyOC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIuMDAwMDAwLCA0MTYuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMC4xNSwxNS41IEMwLjA1LDE1LjQgMCwxNS4yNSAwLDE1LjE1IEMwLDE1LjA1IDAuMDUsMTQuOSAwLjE1LDE0LjggTDAuODUsMTQuMSBDMS4wNSwxMy45IDEuMzUsMTMuOSAxLjU1LDE0LjEgTDEuNiwxNC4xNSBMNC4zNSwxNy4xIEM0LjQ1LDE3LjIgNC42LDE3LjIgNC43LDE3LjEgTDExLjQsMTAuMTUgTDExLjQ1LDEwLjE1IEMxMS42NSw5Ljk1IDExLjk1LDkuOTUgMTIuMTUsMTAuMTUgTDEyLjg1LDEwLjg1IEMxMy4wNSwxMS4wNSAxMy4wNSwxMS4zNSAxMi44NSwxMS41NSBMNC44NSwxOS44NSBDNC43NSwxOS45NSA0LjY1LDIwIDQuNSwyMCBDNC4zNSwyMCA0LjI1LDE5Ljk1IDQuMTUsMTkuODUgTDAuMjUsMTUuNjUgTDAuMTUsMTUuNSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==");
  background-color: transparent;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.form-checkbox input[type="checkbox"]:checked ~ .dot:before,
.form-checkbox input[type="radio"]:checked ~ .dot:before,
.form-radio input[type="checkbox"]:checked ~ .dot:before,
.form-radio input[type="radio"]:checked ~ .dot:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.form-checkbox input[type="checkbox"]:checked ~ .dot:before,
.form-checkbox input[type="checkbox"]:checked ~ span .dot:before,
.form-checkbox input[type="radio"]:checked ~ .dot:before,
.form-radio input[type="checkbox"]:checked ~ .dot:before,
.form-radio input[type="radio"]:checked ~ .dot:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.pages {
  padding: 0;
  margin: 40px 0;
  list-style: none;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
}

.pages.pages--hidden {
  display: none;
}

.pages li {
  margin: 0 11px;
  display: inline-block;
  vertical-align: middle;
}
.pages li a {
  color: #5d6d82;
  -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.pages li a:hover,
.pages li a:focus {
  color: #3d4855;
}
.pages li.active a {
  color: #0e63f4;
}
.pages li.active {
  pointer-events: none;
}
.pages li.prev,
.pages li.next {
  width: 34px;
  height: 34px;
  background: #f8faff
    url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjZweCIgdmlld0JveD0iMCAwIDEwIDYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+UmVjdGFuZ2xlIDEwPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJIZWFkZXIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iMC45Ij4gICAgICAgIDxnIGlkPSJIZWFkZXJfXzEwMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05NjUuMDAwMDAwLCAtMzguMDAwMDAwKSIgZmlsbD0iIzEyNTZlYyIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8ZyBpZD0iSW50cm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYxNS4wMDAwMDAsIDAuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnIGlkPSJIZWFkZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDg4LjAwMDAwMCwgMjYuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iSGVhZGVyLUxvZ2dlZC1JbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDEuMDAwMDAwLCAwLjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC0yIj4gICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIyNC4wMDUzMDMsMTEuMDA1MzAzIEwyMjQuMDA1MzAzLDE1LjY3MTk2OTcgQzIyNC4wMDUzMDMsMTYuMTAxNTI0NSAyMjMuNjU3MDgsMTYuNDQ5NzQ3NSAyMjMuMjI3NTI1LDE2LjQ0OTc0NzUgQzIyMi43OTc5NywxNi40NDk3NDc1IDIyMi40NDk3NDcsMTYuMTAxNTI0NSAyMjIuNDQ5NzQ3LDE1LjY3MTk2OTcgTDIyMi40NDk3NDcsOS40NDk3NDc0NyBMMjI4LjY3MTk3LDkuNDQ5NzQ3NDcgQzIyOS4xMDE1MjQsOS40NDk3NDc0NyAyMjkuNDQ5NzQ3LDkuNzk3OTcwNDQgMjI5LjQ0OTc0NywxMC4yMjc1MjUyIEMyMjkuNDQ5NzQ3LDEwLjY1NzA4MDEgMjI5LjEwMTUyNCwxMS4wMDUzMDMgMjI4LjY3MTk3LDExLjAwNTMwMyBMMjI0LjAwNTMwMywxMS4wMDUzMDMgWiIgaWQ9IlJlY3RhbmdsZS0xMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjI1Ljk0OTc0NywgMTIuOTQ5NzQ3KSBzY2FsZSgxLCAtMSkgcm90YXRlKDQ1LjAwMDAwMCkgdHJhbnNsYXRlKC0yMjUuOTQ5NzQ3LCAtMTIuOTQ5NzQ3KSAiPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=")
    center center no-repeat;
  background-size: 10px auto;
  border-radius: 50%;
  position: relative;
  -webkit-transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.pages li.prev:hover,
.pages li.next:hover,
.pages li.prev:focus,
.pages li.next:focus {
  background-color: #eef3ff;
}
.pages li.prev a,
.pages li.next a {
  width: 100%;
  height: 100%;
  display: block;
}
.pages li.prev {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.pages li.next {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.pages li.ais-Pagination-item--disabled,
.pages li.ais-Pagination-item--disabled:hover {
  background: none;
  pointer-events: none;
}

.ais-Pagination .prev .ais-Pagination-link,
.ais-Pagination .next .ais-Pagination-link {
  opacity: 0;
}

.category {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
}
.category .heading {
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 1.3em;
}
.category .back {
  padding-top: 8px;
  margin-bottom: 16px;
  display: block;
  font-size: 16px;
  line-height: 28px;
}
.category .back:before {
  content: "";
  display: block;
  overflow: hidden;
  width: 22px;
  height: 12px;
  margin: -3px 10px 0 -5px;
  display: inline-block;
  vertical-align: middle;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtMSkiIGZpbGw9IiMxMjU2RUMiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHJlY3QgeT0iNiIgd2lkdGg9IjE1IiBoZWlnaHQ9IjIiIHJ4PSIxIi8+PHBhdGggZD0iTTEzLjQ4NSA2Ljk1TDkuMjQzIDIuNzA3YTEgMSAwIDAgMSAxLjQxNC0xLjQxNGw1LjY1NyA1LjY1Ny01LjY1NyA1LjY1N2ExIDEgMCAwIDEtMS40MTQtMS40MTVsNC4yNDItNC4yNDJ6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L2c+PC9zdmc+")
    0 0 no-repeat;
  background-size: contain;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

@media (min-width: 750px) {
  .category {
    margin-top: 40px;
    margin-bottom: 62px;
  }
  .category .heading {
    max-width: 300px;
    margin-bottom: 26px;
  }
  .category p {
    max-width: 484px;
  }
  .category .back {
    padding-top: 20px;
    margin-bottom: 18px;
  }
}
@media (min-width: 1024px) {
  .category {
    margin-top: 56px;
    margin-bottom: 76px;
  }
  .category .heading {
    max-width: 630px;
    margin-bottom: 12px;
    font-size: 30px;
    line-height: 1.24em;
  }
  .category p {
    max-width: 630px;
  }
  .category .back {
    padding-top: 4px;
  }
}
@media (min-width: 1024px) {
  .category .back {
    padding-top: 16px;
  }
}
